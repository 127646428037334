
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

export default Vue.extend({
    name: 'Menu',
    computed: {
        ...mapGetters({
            users: 'getUsers',
            theme: 'getTheme',
        }),
    },
});
