
import * as _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import * as moment from 'moment';
import 'moment-timezone';
import LastFm from '@/lastfm';
import { User } from '@/charts';

export default Vue.extend({
  name: 'SettingsForm',
  props: {
    timezone: String,
    timezones: Array,
    language: String,
    languages: Array,
    theme: String,
    themes: Array,
  },
  data() {
    return {
      tz: this.$props.timezone,
      lang: this.$props.language,
      th: this.$props.theme,
    };
  },
  methods: {
    onSubmit() {
      this.$store.dispatch('setTimezone', this.$data.tz);
      this.$store.dispatch('setLang', this.$data.lang);
      this.$i18n.locale = this.$data.lang;
      this.$store.dispatch('setTheme', this.$data.th);
      this.$notify({
        group: 'app',
        type: 'success',
        text: this.$t('messages.settings_updated') + '',
      });
    },
    clean() {
      this.$store.dispatch('cleanImages');
      this.$notify({
        group: 'app',
        type: 'success',
        text: this.$t('messages.images_removed') + '',
      });
    },
  },
});
