
import { Component, Vue } from 'vue-property-decorator';
import Menu from '@/components/Menu.vue';
import Footer from '@/components/Footer.vue';
import Theme from './charts/theme';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'App',
  components: {
    Menu,
    Footer,
  },
  computed: {
    ...mapGetters({
      theme: 'getTheme',
    }),
  },
});
