
import { Component, Prop, Vue } from 'vue-property-decorator';
import LastFm from '@/lastfm';
import { User } from '@/charts';

export default Vue.extend({
  name: 'AddUser',
  props: {
    userName: String,
    redirect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      multableUserName: this.userName,
    };
  },
  methods: {
    onSubmit() {
      const $this = this;
      if ($this.multableUserName.length > 0) {
        LastFm.user()
          .getInfo($this.multableUserName)
          .then((response) => {
            const date = new Date(
              response.data.user.registered.unixtime * 1000,
            );
            const user = new User($this.multableUserName, date);
            $this.$store.dispatch('addUser', user);
            if (this.redirect) {
              this.$router.push({ name: 'weekly' });
            }
          })
          .catch((error) => {
            this.$notify({
              group: 'app',
              type: 'error',
              duration: 10000,
              title: 'Oops, something went wrong. Possible problems:',
              text: '<ul>' +
              '<li>You are not connected to the internet.</li>' +
              '<li>Your Last.fm username is incorrect</li></ul>',
            });
          });
      }
    },
    format(value: string, event: any) {
      return value.toLowerCase();
    },
  },
});
